<template>
  <div>
    <nav id="header" class="w-full">
      <div class="flex break-words bg_dark_gray newSty_bg w-full">
        <div class="flex w-full">
          <div class="flex w-full" name="expand">
            <a
              href="#"
              class="hamburger_icon cursor-pointer z-6 ml-2"
              @click="toggleLeftSideBar('TD')"
            >
              <svg
                class="hamburgerTandD"
                xmlns="http://www.w3.org/2000/svg"
                :class="{ ActiveHamburgerTandD: isHamburgerClickedTandD }"
                width="20"
                height="15"
                viewBox="0 0 20 15"
                fill="none"
              >
                <path
                  d="M0 6.25H15V8.75H0V6.25ZM0 0H20V2.5H0V0ZM0 15H9.04375V12.5H0V15Z"
                  :fill="darkDark !== 'white' ? '#ffffff' : '#000000'"
                />
              </svg>
            </a>
            <transition>
              <!-- <nav id="header" class="z-50 w-full z-10"> -->
              <nav id="header" class="w-full">
                <div
                  class="w-full mx-auto flex flex-wrap items-center mt-0 py-0 md:pb-0"
                >
                  <div class="w-1/6 pl-2 md:pl-0"></div>
                  <div class="w-2/4 pl-2 md:pl-0">
                    <ul
                      class="flex flex-col lg:flex-row list-none lg:ml-auto sub_"
                    >
                      <li
                        class="flex items-center text-white text-xs font-thin leading-relaxed inline-block py-1 whitespace-nowrap uppercase mx-4"
                      >
                        <div class="pl-12">
                          <div class="flex items-center mr-2">
                            <input
                              id="radio1"
                              type="radio"
                              name="radio"
                              v-model="selectedRadioBtn"
                              class="hidden"
                              value="drilling"
                              v-on:click="radioButtonClick"
                            />
                            <label
                              for="radio1"
                              class="flex items-center cursor-pointer td_text-xs newSty_text"
                            >
                              <span
                                class="radio_ inline-block mr-2 rounded-full border border-grey flex-no-shrink"
                              ></span>
                              Drilling</label
                            >
                          </div>
                        </div>
                      </li>
                      <li
                        class="flex items-center text-white text-xs font-thin leading-relaxed inline-block py-1 whitespace-nowrap uppercase mx-4"
                      >
                        <div class="pl-12">
                          <div class="flex items-center mr-2">
                            <input
                              id="radio2"
                              type="radio"
                              name="radio"
                              v-model="selectedRadioBtn"
                              class="hidden"
                              value="tripin"
                              v-on:click="radioButtonClick"
                            />
                            <label
                              for="radio2"
                              class="flex items-center cursor-pointer td_text-xs newSty_text"
                            >
                              <span
                                class="radio_ inline-block mr-2 rounded-full border border-grey flex-no-shrink"
                              ></span>
                              Trip In</label
                            >
                          </div>
                        </div>
                      </li>
                      <li
                        class="flex items-center text-white text-xs font-thin leading-relaxed inline-block py-1 whitespace-nowrap uppercase mx-4"
                      >
                        <div class="pl-12">
                          <div class="flex items-center mr-2">
                            <input
                              id="radio3"
                              type="radio"
                              name="radio"
                              v-model="selectedRadioBtn"
                              class="hidden"
                              value="tripout"
                              v-on:click="radioButtonClick"
                            />
                            <label
                              for="radio3"
                              class="flex items-center cursor-pointer td_text-xs newSty_text"
                            >
                              <span
                                class="radio_ inline-block mr-2 rounded-full border border-grey flex-no-shrink"
                              ></span>
                              Trip Out</label
                            >
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="w-1/5 pr-0 ml-auto">
                    <div class="flex relative inline-block">
                      <li
                        class="flex items-center text-white text-xs font-thin leading-relaxed inline-block mr-4 py-1 whitespace-nowrap uppercase td_text-xs"
                        v-on:click="showAllFunction"
                      >
                        <button class="text-md mx-1 py-1 px-2 showAllBtn">
                          <i class="fas fa-search mx-1"></i>

                          Show All
                        </button>
                      </li>
                      <li
                        class="flex items-center text-white text-xs font-thin leading-relaxed inline-block mr-3 py-1 whitespace-nowrap uppercase td_text-xs"
                      >
                        <router-link to="#">
                          <button
                            class="text-white active:bg-emerald-600 td_text-xs font-thin uppercase px-4 py-0.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-2 lg:mb-0 ml-2 mb-3 ease-linear transition-all duration-150 newSty_saveBtn"
                            type="button"
                            @click="toggleModal()"
                            style="backgroundcolor: var(--activeTextColor)"
                          >
                            Save
                          </button>
                        </router-link>
                      </li>
                    </div>
                  </div>
                </div>
              </nav>
            </transition>
          </div>
        </div>
      </div>
    </nav>

    <div
      class="fixed zinindx overflow-y-auto top-0 w-full left-0 hidden model_height model_bg"
      :id="'broomStick-modal'"
    >
      <div
        class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 model_height"
      >
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <div class="container mx-auto px-4 h-full">
          <div class="flex content-center items-center justify-center h-full">
            <div class="w-full lg:w-4/12 modal_width">
              <div
                class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg_model border-0"
              >
                <div class="rounded-t mb-0 px-6 py-6">
                  <div class="text-center mb-3">
                    <img :src="Logo" alt="" class="login_logo" />
                    <h6
                      v-if="!isAlreadySaved"
                      class="text-white text-md font-bold"
                    >
                      SAVE BHA RUN
                    </h6>
                    <h6
                      v-if="isAlreadySaved"
                      class="text-white text-md font-bold"
                    >
                      Save BHA RUN As
                    </h6>
                  </div>
                </div>
                <div class="flex-auto px-4 lg:px-10 py-10 pt-0 modal_body">
                  <div class="relative w-full mb-3">
                    <div class="flex items-center justify-between">
                      <p class="td_label_heading">
                        Start Time <span class="text-red-500">*</span>
                      </p>
                      <div class="fromDateInput mx-2">
                        <Datepicker
                          v-model="fromDate"
                          :dark="darkDark != 'white'"
                          :timezone="time_zone"
                          placeholder="MM/DD/YYYY, hh:mm"
                          :minDate="
                            new Date(startTime).toLocaleString('en-US', {
                              timeZone: this.time_zone,
                            })
                          "
                          @update:modelValue="onFromDateChange"
                        />
                      </div>
                    </div>
                    <div class="flex items-center justify-between mt-3">
                      <p class="td_label_heading">End Time <span class="text-red-500">*</span> </p>
                      <div class="toDateInput mx-2">
                        <Datepicker
                          v-model="toDate"
                          :dark="darkDark != 'white'"
                          :timezone="time_zone"
                          placeholder="MM/DD/YYYY, hh:mm"
                          :minDate="
                            new Date(startTime).toLocaleString('en-US', {
                              timeZone: this.time_zone,
                            })
                          "
                          :max-date="
                            new Date(wellEndDate).toLocaleString('en-US', {
                              timeZone: this.time_zone,
                            })
                          "
                          @update:modelValue="onToDateChange"
                        />
                        <!-- <span class="text-red-500 absolute w-full mr-16" v-if="toDate < fromDate ">
                            please select valid date
                          </span> -->
                      </div>
                    </div>
                    <label
                      class="block uppercase text-white text-xs font-bold mb-2 text-left mt-1 label_"
                      htmlFor="grid-password"
                    >
                      Name <span class="text-red-500 mx-1">*</span>
                    </label>
                    <input
                      type="text"
                      class="border-0 px-3 py-3 placeholder-blueGray-50 text-white input-bg rounded text-xs shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      v-model="run_name"
                    />
                  </div>

                  <div class="text-center mt-6 flex">
                    <button
                      type="button"
                      class="px-4 bg-gray-500 text-white text-sm bg_cancel bg_modal_btns rounded hover:bg-gray-700 text-sm font-bold button uppercase px-6 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 py-1"
                      @click="toggleModal()"
                    >
                      <i class="fas fa-times"></i> Cancel
                    </button>

                    <button
                      class="bg-green-600 bg_create bg_modal_btns text-white active:bg-green-800 text-sm font-bold button uppercase px-6 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 py-1"
                      type="button"
                      @click="saveBHARun"
                    >
                      <i class="fas fa-plus"></i> Save
                    </button>
                  </div>
                  <div class="text-red-500">
                    {{ errcode }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
export default {
  components: {
    Datepicker,
  },
  props: {
    onToggleSideBar: Function,
    showAll: Function,
    onChangeValues: Function,
    onSaveBha: Function,
    displayId: String,
    widgetId: String,
    isSaved: Boolean,
    time_zone: String,
    startTime: String,
    setDrilligAsDefault: {
      type: Boolean,
    },
  },
  setup() {
    return {};
  },
  data() {
    return {
      selectedRadioBtn: "drilling",
      isHamburgerClickedTandD: false,
      sub_nav_show: true,
      fromDate: "",
      toDate: null,
      wellEndDate: null,
      run_name: "",
    };
  },
  setup() {},
  async mounted() {
    // this.fromDate = new Date(this.startTime).getTime()
  },
  methods: {
    saveBHARun(){
      if(this.fromDate && this.toDate && this.run_name){
        this.onSaveBha(this.run_name)
      }
      else{
        if(!this.fromDate){
          this.$toast.clear();
          this.$toast.error(`Start time is mandatory`,
            {duration: "2000", position: 'top', pauseOnHover: true}
          )
        }
        else if(!this.toDate){
          this.$toast.clear();
          this.$toast.error(`End time is mandatory`,
            {duration: "2000", position: 'top', pauseOnHover: true}
          )
        }
        else if(!this.run_name){
          this.$toast.clear();
          this.$toast.error(`Name is mandatory`,
            {duration: "2000", position: 'top', pauseOnHover: true}
          )
        }
      }
    },
    onFromDateChange() {
      if(this.toDate != '' && new Date(this.toDate) < new Date(this.fromDate)){
        this.fromDate = new Date(this.startTime).getTime() + 86400000;
        this.$toast.clear();
        this.$toast.error(`Start time should be lower than end time`,
          {duration: "2000", position: 'top', pauseOnHover: true}
        )
        return;
      }
      this.$store.dispatch("rect/bhaStartTime", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: new Date(this.fromDate),
      });
    },
    onToDateChange() {
      if(new Date(this.toDate) < new Date(this.fromDate)){
        this.toDate = '';
        this.$toast.clear()
        this.$toast.error(`End time should be higher than start time`,
          {duration: "2000", position: 'top', pauseOnHover: true}
        )
        return;
      }
      this.$store.dispatch("rect/bhaEndTime", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: new Date(this.toDate),
      });
    },
    toggleModal() {
      this.toDate = "";
      this.errcode = "";
      this.run_name = "";
      this.fromDate = new Date(this.startTime).getTime() + 86400000;
      if (
        this.$store.state.disp.displays &&
        this.$store.state.disp.displays[this.displayId]
      ) {
        const { max } = this.$store.state.disp.displays[this.displayId];
        this.wellEndDate = max;
      }
      this.$store.dispatch("rect/bhaStartTime", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: this.fromDate,
      });
      this.$store.dispatch("rect/bhaEndTime", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: "",
      });
      // document.getElementById("broomStick-modal").classList.toggle("visible");
      if (
        document
          .getElementById("broomStick-modal")
          .classList.value.indexOf("hidden") > -1
      ) {
        // for (let rect of this.$store.state.rect.rects) {
        // if (rect["backgroundId"] == this.backgroundId) {
        //   if (rect.hasOwnProperty("savedId")) {
        //     this.isAlreadySaved = rect["savedId"];
        //     this.run_name = rect["savedName"];
        //   }
        // }
        // }
      }
      document.getElementById("broomStick-modal").classList.toggle("hidden");
    },
    radioButtonClick(e) {
      this.onChangeValues(e.target.value);
    },
    showAllFunction() {
      this.showAll();
    },
    toggleLeftSideBar() {
      this.onToggleSideBar();

      if (!document.querySelector(".c-toast")) {
        this.isHamburgerClickedTandD = !this.isHamburgerClickedTandD;
      }
    },
    // toggleLeftSideDbBar: function (action) {
    //   this.onToggleSideBar(action);
    // },

    dark() {
      document.querySelector("body").classList.add("dark-mode");
      // this.darkMode = true
      if (this.$store.state.rect.darkmode) {
        // alert("daark" + this.$store.state.rect.darkmode);
      }

      this.$emit("dark");
    },

    light() {
      document.querySelector("body").classList.remove("dark-mode");
      // this.darkMode = false
      if (!this.$store.state.rect.darkmode) {
        // alert("light" + this.$store.state.rect.darkmode);
      }

      this.$emit("light");
    },

    modeToggle() {
      this.$store.dispatch("rect/toggleDarkMode");
    },
  },
  computed: {
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
  },
  watch: {
    setDrilligAsDefault() {
      this.selectedRadioBtn = "drilling";
    },
    isSaved(newVal) {
      this.toggleModal();
    },
    time_zone(newVal) {
      console.log("time_zone changed td nav bar : ", newVal);
    },
    // startTime(newVal) {

    //   // this.fromDate = new Date(newVal).getTime() + 86400
    //   console.log("🚀 ➽ file: TDNavbar.vue:314  ➽ startTime  ➽ this.fromDate ⏩" , this.fromDate)
    // }
  },
};
</script>
<style scopped>
.bg_gray {
  background-color: #141414;
}

.bg_gray_600 {
  background-color: #2e2e2e;
}

.nav_bar {
  font-size: 14px;
  color: #ffffff50;
}

input[type="radio"] + label span {
  transition: background 0.2s, transform 0.2s;
}

input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span {
  transform: scale(1.2);
}

input[type="radio"]:checked + label span {
  background-color: var(--activeTextColor);
  box-shadow: 0px 0px 0px 2px white inset;
}

input[type="radio"]:checked + label {
  color: var(--activeTextColor);
}

.td_text-xs {
  font-size: calc(0.13vw + 0.1vh + 0.65vmin);
}

.radio_ {
  width: 13px;
  height: 13px;
  border: 1px solid gray;
}

.dp__input {
  outline: none;
  width: 100%;
  font-size: min(0.9vw, 12px);
  font-weight: 600;
  line-height: 0.1rem;
  padding: 4px 0 4px 6px;
  /* color: #ffffff; */
  border: 0.5px solid #898989;
  /* text-align: center; */
  height: 20px;
  background: #0e1016;
  color: white;
  border: 1px solid gray;
}

.dp__input_icons {
  padding: 6px 4px;
  /* color: #ffffff; */
}

.dp__input_icon {
  top: 50%;
  width: 12%;
  color: #f90909;
  padding: 3px;
  display: none;
}

.dp__clear_icon {
  top: 57%;
  right: 0;
  width: 8px;
}

.dp__menu_min_width {
  width: 100px !important;
}

.dp__cell_inner {
  height: 16px;
  width: 17px;
  font-size: 10px;
  /* color: white; */
}

.dp__action_row {
  padding: 6px 10px;
  height: 27px;
  font-size: 10px;
}

.dp__selection_preview {
  font-size: 0.53rem;
}

.dp__calendar_header_item {
  height: 20px;
  padding: 5px;
  width: 18px;
  font-size: 9px;
  /* color: white; */
}

.dp__month_year_select {
  width: 25%;
  height: 14px;
  font-size: 10px;
  /* color: white; */
}

.dp__calendar_wrap {
  width: 150px;
}

.dp__menu {
  min-width: 150px;
}

.dp__action {
  font-size: 8px;
  padding: 2px 2px;
}

.dp__selection_preview {
  font-size: 0.46rem;
  color: #1976d2;
}

.dp__button {
  padding: 8px;
  height: 16px;
}

.dp__button svg {
  height: 15px;
}

.dp__time_display {
  font-size: 10px;
  /* color: white; */
}

.dp__inc_dec_button {
  height: 36px;
  width: 26px;
}

::-webkit-input-placeholder {
  /* Edge */
  color: gray !important;
}

:-ms-input-placeholder {
  /* Internet Explorer */
  color: gray !important;
}

::placeholder {
  color: gray !important;
}

.dp__cell_offset {
  color: var(--dp-secondary-color);
}

.dp__month_year_row {
  justify-content: center;
}

.dp__overlay_cell_pad {
  padding: 8px 0;
  font-size: 10px;
}

.dp__theme_dark {
  --dp-background-color: #0e1016 !important;
}

.dp__theme_light {
  --dp-background-color: #ffffff;
}

.td_label_heading {
  color: white;
}


.newSty_text {
  letter-spacing: 1px;
  font-weight: 600;
  color: var(--colorBoxWhiteNum);
}

.newSty_saveBtn {
  background-color: var(--activeTextColor);
  font-size: 9px;
  letter-spacing: 1px;
  font-weight: 600;

  padding-top: 2px;
  padding-bottom: 1px;
}

.newSty_bg {
  background: var(--central_bg) !important;
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.15) !important;
}

.showAllBtn {
  letter-spacing: 1px;
  color: var(--activeTextColor);
  border: 1px solid var(--activeTextColor);
  font-size: 9px;
  font-weight: 700;
  padding-top: 5px;
}

.hamburgerTandD path {
  fill: var(--textColor);
}

/* .ActiveHamburgerTandD path {
  fill: var(--activeHamburger);
} */
.zinindx {
  z-index: 9999999;
}
</style>
