<template>
  <div class="flex relative">
    <canvas
      :ref="`cursorCanvasBroomStick${id}`"
      class="cursor_can"
      :width="width"
      :height="height"
      @mousemove="handleSelectionMove"
      @mouseleave="crossHairMouseLeave"
      :style="{ 'margin-left': x + 'px', 'margin-top': y + 'px' }"
    ></canvas>
    <canvas :ref="id" :width="totl_width" :height="totl_height"></canvas>
    <!-- <canvas
      :ref="id"
      :width="totl_width"
      :height="totl_height"
      @mousemove="handleSelectionMove"
      @mousedown="handleSelectionStart" 
      @mouseup="handleSelectionEnd"
    ></canvas> -->
    <div
      v-if="tooltip"
      class="tooltip"
      :style="{ left: tooltip.left + 'px', top: tooltip.top + 'px' }"
    >
      <template v-if="tooltip.rt">
        <div>
          {{ `BIT DEPTH : ${tooltip.data.y.toFixed(2)}` }}
          {{ `\nHOLE DEPTH : ${tooltip.data.dmea.toFixed(2)}` }}
          {{ `\n${tooltip.data.name}` }} : {{ tooltip.data.x.toFixed(2) }}
          {{ `\nTIME : ${tooltip.data.time}` }}
        </div>
      </template>
      <template v-else>
        <div v-for="(tp, ind) in tooltip.data" :key="ind">
          {{ tp.name }} : {{ tp.x.toFixed(2) }}

          {{ ind == tooltip.data.length - 1 ? `\nMD:${tp.y}` : "" }}
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { scaleLinear, zoom, select, pointer, bisector, extent } from "d3";
import helperServices from '../../../helper-services.js'
export default {
  name: "TNDLineChart",
  props: {
    totl_width: {
      type: Number,
      required: false,
      default: 600,
    },
    totl_height: {
      type: Number,
      required: false,
      default: 300,
    },
    data_set: {
      type: Array,
      required: true,
    },
    min_X: {
      type: Number,
      default: 0,
    },
    min_Y: {
      type: Number,
      default: 0,
    },
    max_X: {
      type: Number,
      default: 100,
    },
    max_Y: {
      type: Number,
      default: 100,
    },
    id: {
      type: String,
      default: "mycanvasId",
    },
    diamondShapeData: {
      type: Array,
      default: [],
    },
    wellFormationData: {
      type: Array,
      default: [],
    },
    geometryData: {
      type: Array,
      default: [],
    },
    diamondShapeType: {
      type: String,
      default: "drilling",
    },
    enableZoom: {
      type: Boolean,
      default: false,
    },
    isRealtime: {
      type: Boolean,
      default: true,
    },
    graphName: {
      type: String,
      default: "",
    },
    hklOrTor: {
      type: String,
      default: "TORQUE",
    },
    unit: {
      type: String,
      default: " (k ft-lbf)",
    },
    displayId:{
      type: String,
      required:false
    }
  },
  data() {
    return {
      minX: 0,
      minY: 0,
      maxX: 0,
      maxY: 0,
      unitsPerTickX: 200,
      unitsPerTickY: 2100,
      yAxisTitle: "DEPTH",
      xAxisTitle: "TORQUE (k ft-lbf)",

      padding: 20,
      tickSize: 6,
      pointRadius: 5,
      font: "10pt Calibri",
      fontHeight: 12,
      canvas: null,
      context: null,
      tooltip: null,
      mouseX: 0,
      isResetZoomBtnEnable: false,
      zoomed_X_Axis: null,
      zoomed_Y_Axis: null,
      width: 0,
      height: 0,
      canvasBroomStick: null,
      contextBroomStick: null,
      displayXUnit:'',
      displayYUnit:'',
    };
  },
  computed: {
    numXTicks() {
      return 10;
    },
    numYTicks() {
      return 10;
    },
    x() {
      return this.padding * 4;
    },
    y() {
      return this.padding * 2;
    },
    // width() {
    //   return this.canvas.width - this.x - this.padding * 2;
    // },
    // height() {
    //   return this.canvas.height - this.y - this.padding * 3 - this.fontHeight;
    // },

    xScale() {
      return scaleLinear()
        .domain([this.minX, this.maxX])
        .range([this.x, this.x + this.width])
        .nice();
    },
    yScale() {
      return scaleLinear()
        .domain([this.minY, this.maxY])
        .range([this.y, this.y + this.height]);
    },
    axisColor() {
      return getComputedStyle(this.canvas).getPropertyValue("--textColor");
    },
  },
  methods: {
    zoomed({ transform }) {
      if (!this.enableZoom) return;
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      // Update scales with zoom
      const new_xScale = transform.rescaleX(this.xScale);
      const new_yScale = transform.rescaleY(this.yScale);

      this.zoomed_X_Axis = new_xScale;
      this.zoomed_Y_Axis = new_yScale;
      // Update the scales
      let minX = new_xScale.domain()[0];
      let maxX = new_xScale.domain()[1];
      let minY = new_yScale.domain()[0];
      let maxY = new_yScale.domain()[1];
      // console.log("this.minX ", minX, "maxX ", maxX, "minY ", minY, "maxY ", maxY);
      // Clear and redraw the chart with the updated scales
      this.clearCanvas();
      this.updateScale();
      this.data_set.forEach((d) => {
        if (d.data?.length > 0) {
          let filteredData = d.data.filter(
            (element) =>
              minX < Number(element.x) &&
              Number(element.x) < maxX &&
              minY < Number(element.y) &&
              maxY > Number(element.y)
          );
          this.drawLine(filteredData, d.color, d.width, d.lineType);
        }
      });
      if (this.diamondShapeData.length > 0) {
        let filteredData = this.diamondShapeData.filter(
          (element) =>
            minX < Number(element.x) &&
            Number(element.x) < maxX &&
            minY < Number(element.y) &&
            maxY > Number(element.y)
        );
        this.drawDiamond(filteredData);
      }
      if (this.wellFormationData.length > 0) {
        // console.log("this.wellFormationData ", this.wellFormationData);
        let filteredData = this.wellFormationData.filter(
          (element) => minY < Number(element.md) && maxY > Number(element.md)
        );
        this.drawHorizantalLine(filteredData);
      }
      if (this.geometryData.length > 0) {
        // let filteredData = this.geometryData.filter(
        //   (element) =>
        //     minY < Number(element.y) &&
        //     maxY > Number(element.y)
        // );
        this.drawGeoData(this.geometryData);
      }
      this.isResetZoomBtnEnable = true;
    },
    inItChat() {
      this.minX = this.min_X;
      this.minY = this.min_Y;
      this.maxX = this.max_X;
      this.maxY = this.max_Y;
      const zoom1 = zoom()
        .scaleExtent([1, 50])
        .translateExtent([
          [this.x, this.y],
          [this.width, this.height],
        ])
        .extent([
          [this.x, this.y],
          [this.width, this.height],
        ])
        .on("zoom", this.zoomed);

      select(this.canvasBroomStick).call(zoom1);

      this.drawXAxis();
      this.drawYAxis();
      this.data_set.forEach((d) => {
        if (d.data?.length > 0)
          this.drawLine(d.data, d.color, d.width, d.lineType);
      });
      if (this.diamondShapeData.length > 0 && this.isRealtime) {
        this.drawDiamond(this.diamondShapeData);
      }
      if (this.wellFormationData.length > 0) {
        this.drawHorizantalLine(this.wellFormationData);
      }
      if (this.geometryData.length > 0) {
        this.drawGeoData(this.geometryData);
      }
    },

    drawXAxis() {
      let context = this.context;
      context.save();
      context.beginPath();
      context.moveTo(this.x, this.y + this.height);
      context.lineTo(this.x + this.width, this.y + this.height);
      context.strokeStyle = this.axisColor;
      context.lineWidth = 2;
      context.stroke();

      let xAxisScale = this.xScale;
      if (this.zoomed_X_Axis) xAxisScale = this.zoomed_X_Axis;

      let xAxisTicks = xAxisScale.ticks(this.numXTicks);
      // let xAxisTicks = this.xScale.ticks(this.numXTicks);
      xAxisTicks.forEach((tick) => {
        const xPos = xAxisScale(tick);
        //Draw tick & label
        context.beginPath();
        context.strokeStyle = this.axisColor;
        context.moveTo(xPos, this.y + this.height);
        context.lineTo(xPos, this.y + this.height + this.tickSize);
        context.stroke();

        context.font = this.font;
        context.fillStyle = this.axisColor;
        context.textAlign = "center";
        context.textBaseline = "middle";

        context.fillText(
          this.formatNumber(tick),
          xPos,
          this.y + this.height + this.padding
        );
        //add grig gridlines
        context.closePath();
        context.beginPath();
        context.moveTo(xPos, this.y);
        context.lineTo(xPos, this.y + this.height);
        context.strokeStyle = this.axisColor + "b3";
        context.lineWidth = 0.5;
        context.setLineDash([5, 5]);
        context.stroke();
        context.closePath();
      });
      context.rotate(-Math.PI / 2);
      context.fillText(
        `${this.yAxisTitle} (${this.displayYUnit || ''})`,
        -this.y - this.height / 2 + 20,
        this.padding - 5
      );

      context.restore();
    },

    drawYAxis() {
      let context = this.context;
      context.save();
      context.beginPath();
      context.moveTo(this.x, this.y);
      context.lineTo(this.x, this.y + this.height);
      context.strokeStyle = this.axisColor;
      context.lineWidth = 2;
      context.stroke();

      let yAxis = this.yScale;
      if (this.zoomed_Y_Axis) yAxis = this.zoomed_Y_Axis;

      const tickValues = yAxis.ticks(this.numYTicks);

      tickValues.forEach((tick, i) => {
        const yPos = yAxis(tick);
        //Draw tick & label
        context.beginPath();
        context.strokeStyle = this.axisColor;
        context.moveTo(this.x - this.tickSize, yPos);
        context.lineTo(this.x, yPos);
        context.stroke();

        context.font = this.font;
        context.fillStyle = this.axisColor;
        context.textAlign = "right";
        context.textBaseline = "middle";
        context.fillText(this.formatNumber(tick), this.x - this.padding, yPos);
        context.closePath();
        //Draw gridlines

        context.beginPath();
        context.moveTo(this.x, yPos);
        context.lineTo(this.x + this.width, yPos);
        context.strokeStyle = this.axisColor + "b3";
        context.lineWidth = 0.5;
        context.setLineDash([5, 5]);
        context.stroke();
        context.closePath();
      });
      context.fillText(`${this.hklOrTor} (${this.displayXUnit || ''})`, this.width / 2, this.height + 85);
      let textWidth = context.measureText(this.graphName).width;
      context.fillText(
        this.graphName,
        this.width - textWidth,
        this.height + 85
      );
      context.restore();
    },

    drawLine(data, color, width, lineType = null) {
      let context = this.context;
      context.save();
      context.lineWidth = width;
      context.strokeStyle = color;
      context.fillStyle = color;
      context.beginPath();

      let xScale = this.xScale;
      let yScale = this.yScale;
      if (this.zoomed_X_Axis) xScale = this.zoomed_X_Axis;
      if (this.zoomed_Y_Axis) yScale = this.zoomed_Y_Axis;

      if (data.length > 0) {
        // Move to the starting point of the line
        const startX = Math.max(0, Math.min(this.width, xScale(data[0].x)));
        const startY = Math.max(0, Math.min(this.height, yScale(data[0].y)));
        context.moveTo(startX, startY);

        if (lineType) context.setLineDash(lineType);

        // Draw the line segment by segment
        for (var n = 1; n < data.length; n++) {
          var point = data[n];
          // Ensure the y-coordinate is within the chart area
          context.lineTo(xScale(point.x), yScale(point.y));
        }
      }

      // Stroke and close the path
      context.stroke();
      context.closePath();

      context.restore();
      context.setLineDash([]);
    },

    drawDiamond(data) {
      let context = this.context;
      context.save();
      data = data.filter((d) => d.type == this.diamondShapeType);
      // const [min,max] = extent(data,(d)=>d.x);
      let xScale = this.xScale;
      let yScale = this.yScale;

      // if(min)xScale= scaleLinear().domain([min, this.maxX]).range([this.x, this.x + this.width]).nice();
      if (this.zoomed_X_Axis) xScale = this.zoomed_X_Axis.range(xScale.range());
      if (this.zoomed_Y_Axis) yScale = this.zoomed_Y_Axis.range(yScale.range());

      data.forEach((point) => {
        if (point && point.x !== undefined && point.y !== undefined) {
          context.fillStyle = point.color;
          const x = Math.max(0, Math.min(this.width + this.x, xScale(point.x)));
          const y = Math.max(0, Math.min(this.height+this.y, yScale(point.y)));
          context.beginPath();
          context.moveTo(x, y - this.pointRadius);
          context.lineTo(x + this.pointRadius, y);
          context.lineTo(x, y + this.pointRadius);
          context.lineTo(x - this.pointRadius, y);
          context.closePath();
          context.fill();
        }
      });

      context.restore();
    },

    drawHorizantalLine(data) {
      let yScale = scaleLinear()
        .domain([this.minY, this.maxY])
        .range([this.y, this.height]);
      if (this.zoomed_Y_Axis) yScale = this.zoomed_Y_Axis;
      let context = this.context;
      context.save();
      data.forEach((d, i) => {
        const yPos = yScale(d.md);
        if(yPos<=this.height){

        
        context.beginPath();
        context.globalAlpha = 0.5;
        context.moveTo(this.x, yPos);
        context.lineTo(this.x + this.width, yPos);
        context.strokeStyle = "#E6CC1A";
        context.lineWidth = 2;
        context.setLineDash([10, 10]);
        context.stroke();
        context.closePath();

        context.beginPath();
        context.globalAlpha = 1;
        context.strokeStyle = this.axisColor;
        context.lineWidth = 0.4;
        context.setLineDash([]);
        context.moveTo(this.width * 0.7, yPos);
        let y_position = null;
        if (i == 0) {
          y_position = yPos;
        } else {
          y_position = yScale(data[0].md) + 15 * i;
        }
        // context.lineTo(this.width * 0.75, yPos + 10 * i);
        // context.lineTo(this.width * 0.85, yPos + 10 * i);
        context.lineTo(this.width * 0.75, y_position);
        context.lineTo(this.width * 0.85, y_position);
        context.textAlign = "right";
        context.textBaseline = "bottom";
        context.fillStyle = this.axisColor;
        // context.fillText(d.formation, this.width * 0.85, yPos + 10 * i);
        context.fillText(d.formation, this.width * 0.85, y_position);
        context.stroke();
      }
      });
      context.globalAlpha = 1;
      context.restore();
    },
    drawGeoData(data) {
      let yScale = this.yScale;
      if (this.zoomed_Y_Axis) yScale = this.zoomed_Y_Axis;
      let context = this.context;
      context.save();
      console.log(" yScale domains : ", yScale.domain()[0]);
      data.forEach((d, i) => {
        if (yScale.domain()[0] >= d.md || yScale.domain()[1] <= d.md ) return true;
        const yPos = yScale(d.md);
        context.beginPath();
        context.moveTo(this.x, yPos);
        context.lineTo(this.x, yPos + 15);
        context.lineTo(this.x + 10, yPos + 15);
        context.lineTo(this.x, yPos);
        context.fillStyle = "red";
        context.fill();
        context.fillStyle = "white";
        let title = d.odia;
        title += ` "${d.section_type == "Open Hole" ? "OH" : "CSG"}"`;
        context.fillText(title, this.x + 15, yPos + 15);
      });
      context.restore();
    },

    updateScale() {
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.drawXAxis();
      this.drawYAxis();
    },

    handleSelectionStart(event) {
      if (!this.enableZoom) return;
      // Track the starting point of the mouse drag
      const rect = this.canvas.getBoundingClientRect();
      this.selectionStart = {
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
      };
      this.selectionEnd = {
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
      };
      this.isSelecting = true;
    },
    handleSelectionMove(event) {
      // this.handleMouseMoveForTooltip(event);
      if (this.data_set.length != 0) {
        this.crossHairMousemove(event);
      }
      // this.crossHairMousemove(event);
      // if (!this.enableZoom) {
      //   this.handleMouseMoveForTooltip(event);
      //   return;
      // }
      // if (this.isSelecting) {
      //   // Track the current mouse position during the drag
      //   const rect = this.canvas.getBoundingClientRect();
      //   this.selectionEnd = {
      //     x: event.clientX - rect.left,
      //     y: event.clientY - rect.top,
      //   };
      //   this.drawSelectionRectangle();
      // }
    },
    handleSelectionEnd() {
      if (!this.enableZoom) return;
      if (this.isSelecting) {
        this.isSelecting = false;

        // Calculate the zoom area based on the selected rectangle
        const selectedArea = {
          minX: Math.min(this.selectionStart.x, this.selectionEnd.x),
          minY: Math.max(this.selectionStart.y, this.selectionEnd.y),
          maxX: Math.max(this.selectionStart.x, this.selectionEnd.x),
          maxY: Math.min(this.selectionStart.y, this.selectionEnd.y),
        };

        const area =
          (selectedArea.maxX - selectedArea.minX) *
          (selectedArea.minY - selectedArea.maxY);

        const minSelectedArea = 300;
        if (area > minSelectedArea) {
          // Calculate the new scales based on the selected area
          const newMinX = this.xScale.invert(selectedArea.minX);
          const newMaxX = this.xScale.invert(selectedArea.maxX);
          const newMinY = this.yScale.invert(selectedArea.maxY);
          const newMaxY = this.yScale.invert(selectedArea.minY);

          // Update the scales
          this.minX = newMinX;
          this.maxX = newMaxX;
          this.minY = newMinY;
          this.maxY = newMaxY;

          // Clear and redraw the chart with the updated scales
          this.clearCanvas();
          this.updateScale();
          this.data_set.forEach((d) => {
            if (d.data?.length > 0) {
              let filteredData = d.data.filter(
                (element) =>
                  this.minX < Number(element.x) &&
                  Number(element.x) < this.maxX &&
                  this.minY < Number(element.y) &&
                  this.maxY > Number(element.y)
              );
              this.drawLine(filteredData, d.color, d.width, d.lineType);
            }
          });
          if (this.diamondShapeData.length > 0 && this.isRealtime) {
            let filteredData = this.diamondShapeData.filter(
              (element) =>
                this.minX < Number(element.x) &&
                Number(element.x) < this.maxX &&
                this.minY < Number(element.y) &&
                this.maxY > Number(element.y)
            );
            this.drawDiamond(filteredData);
          }
          if (this.wellFormationData.length > 0) {
            let filteredData = this.wellFormationData.filter(
              (element) =>
                this.minX < Number(element.x) &&
                Number(element.x) < this.maxX &&
                this.minY < Number(element.y) &&
                this.maxY > Number(element.y)
            );
            this.drawHorizantalLine(this.wellFormationData);
          }
          if (this.geometryData.length > 0) {
            this.drawGeoData(this.geometryData);
          }
          this.isResetZoomBtnEnable = true;
        }
      }
    },

    drawSelectionRectangle() {
      // Draw the selection rectangle on the canvas
      const context = this.context;
      context.fillStyle = "rgba(0, 0, 255, 0.2)";
      context.fillRect(
        this.selectionStart.x,
        this.selectionStart.y,
        this.selectionEnd.x - this.selectionStart.x,
        this.selectionEnd.y - this.selectionStart.y
      );
    },

    clearCanvas() {
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    },
    resetZoom() {
      this.zoomed_X_Axis = null;
      this.zoomed_Y_Axis = null; // Reset the zoom to the original scale
      this.minX = this.min_X;
      this.minY = this.min_Y;
      this.maxX = this.max_X;
      this.maxY = this.max_Y;

      // Redraw the chart with the updated scales
      this.updateScale();
      this.data_set.forEach((d) => {
        if (d.data?.length > 0)
          this.drawLine(d.data, d.color, d.width, d.lineType);
      });
      if (this.diamondShapeData.length > 0 && this.isRealtime) {
        this.drawDiamond(this.diamondShapeData);
      }
      if (this.wellFormationData.length > 0) {
        this.drawHorizantalLine(this.wellFormationData);
      }
      if (this.geometryData.length > 0) {
        this.drawGeoData(this.geometryData);
      }
      this.isResetZoomBtnEnable = false;
    },

    handleMouseMoveForTooltip(event) {
      const rect = this.canvas.getBoundingClientRect();
      const mouseX = event.clientX - rect.left;
      const mouseY = event.clientY - rect.top;

      // Perform logic to find the data point corresponding to the mouse coordinates
      const dataPoint =
        this.findDataPoint(mouseX, mouseY) ||
        this.findDiamondPoint(mouseX, mouseY) ||
        this.findGeometryPoint(mouseX, mouseY);

      if (dataPoint) {
        // Use transformed coordinates for tooltip positioning
        const transformedCoordinates = this.transformContextForMouseCoords(
          mouseX,
          mouseY
        );
        // Update tooltip position and content
        this.tooltip = {
          left: transformedCoordinates.x,
          top: this.canvas.height - transformedCoordinates.y - this.padding, // Adjust for bottom to top display
          content: `${dataPoint.name ? dataPoint.name : "X"}: ${
            dataPoint.x
          }, MD: ${dataPoint.y}`,
        };
      } else {
        // Hide tooltip if no data point is found
        this.tooltip = null;
      }
    },

    transformContextForMouseCoords(mouseX, mouseY) {
      const context = this.context;
      context.save();
      context.translate(this.x, this.y + this.height);
      context.scale(1, -1); // Flip the Y-axis scaling factor
      let numX = 0;
      if (mouseX > this.width) {
        numX = 300;
      }
      const transformedX = mouseX - this.x - numX;
      let numY = 0;
      if (mouseY > this.height) {
        numY = 55;
      }
      const transformedY = this.height - (mouseY - this.y - numY);

      context.restore();

      return { x: transformedX, y: transformedY };
    },

    findDataPoint(mouseX, mouseY) {
      // Iterate through your data points to find the one closest to the mouse coordinates
      for (const dataset of this.data_set) {
        for (const dataPoint of dataset.data) {
          let xScale = this.xScale;
          let yScale = this.yScale;
          if (this.zoomed_X_Axis) xScale = this.zoomed_X_Axis;
          if (this.zoomed_Y_Axis) yScale = this.zoomed_Y_Axis;

          const x = xScale(dataPoint.x);
          const y = yScale(dataPoint.y);
          const distance = Math.sqrt((x - mouseX) ** 2 + (y - mouseY) ** 2);

          // Adjust the threshold based on your requirements
          if (distance < 10) {
            return dataPoint;
          }
        }
      }

      return null; // No data point found
    },
    findDataPoint(mouseX, mouseY) {
      // Iterate through your data points to find the one closest to the mouse coordinates
      for (const dataset of this.data_set) {
        for (const dataPoint of dataset.data) {
          let xScale = this.xScale;
          let yScale = this.yScale;
          if (this.zoomed_X_Axis) xScale = this.zoomed_X_Axis;
          if (this.zoomed_Y_Axis) yScale = this.zoomed_Y_Axis;

          const x = xScale(dataPoint.x);
          const y = yScale(dataPoint.y);
          const distance = Math.sqrt((x - mouseX) ** 2 + (y - mouseY) ** 2);

          // Adjust the threshold based on your requirements
          if (distance < 10) {
            return dataPoint;
          }
        }
      }

      return null; // No data point found
    },
    findDiamondPoint(mouseX, mouseY) {
      const data = this.diamondShapeData.filter(
        (d) => d.type == this.diamondShapeType
      );
      // const [min,max] = extent(data,(d)=>d.x);
      for (const dataPoint of data) {
        let xScale = this.xScale;
        let yScale = this.yScale;
        // if(min)xScale= scaleLinear().domain([min, this.maxX]).range([this.x, this.x + this.width]).nice();
        if (this.zoomed_X_Axis) xScale = this.zoomed_X_Axis;
        if (this.zoomed_Y_Axis) yScale = this.zoomed_Y_Axis;
        const x = xScale(dataPoint.x);
        const y = yScale(dataPoint.y);
        const distance = Math.sqrt((x - mouseX) ** 2 + (y - mouseY) ** 2);

        // Adjust the threshold based on your requirements
        if (distance < 10) {
          return dataPoint;
        }
      }

      return null; // No data point found in diamondShapeData
    },

    findGeometryPoint(mouseX, mouseY) {
      for (const dataPoint of this.geometryData) {
        let xScale = this.xScale;
        let yScale = this.yScale;
        if (this.zoomed_X_Axis) xScale = this.zoomed_X_Axis;
        if (this.zoomed_Y_Axis) yScale = this.zoomed_Y_Axis;
        const x = xScale(dataPoint.x);
        const y = yScale(dataPoint.y);
        const distance = Math.sqrt((x - mouseX) ** 2 + (y - mouseY) ** 2);

        // Adjust the threshold based on your requirements
        if (distance < 10) {
          return dataPoint;
        }
      }

      return null; // No data point found in geometryData
    },

    formatNumber(number) {
      const suffixes = ["", "k", "M", "B", "T"]; // Add more suffixes as needed
      let suffixIndex = 0;

      while (number >= 1000 && suffixIndex < suffixes.length - 1) {
        number /= 1000;
        suffixIndex++;
      }

      return Number(number.toFixed(1)) + suffixes[suffixIndex];
    },
    crossHairMousemove(event) {
      const rect = this.canvasBroomStick.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      // const mouseX = event.clientX - rect.left;
      // const mouseY = event.clientY - rect.top;
      this.contextBroomStick.clearRect(
        0,
        0,
        this.canvasBroomStick.width,
        this.canvasBroomStick.height
      );
      this.contextBroomStick.beginPath();
      this.contextBroomStick.moveTo(0, y);
      this.contextBroomStick.lineTo(this.width, y);
      // this.contextBroomStick.moveTo(x, 0);
      // this.contextBroomStick.lineTo(x, this.width);
      this.contextBroomStick.strokeStyle = this.axisColor;
      this.contextBroomStick.stroke();
      this.contextBroomStick.closePath();
      let yScale = this.yScale;
      if (this.zoomed_Y_Axis) yScale = this.zoomed_Y_Axis;
      let bisectDate = bisector(function (d) {
        return d.y;
      }).left;

      let x0 = yScale.invert(y + this.y);
      let index = bisectDate(this.data_set[0].data, x0, 1);
      // let indexDiamond = bisectDate(this.diamondShapeData, x0, 1);

      let tp = [];
      for (const dataset of this.data_set) {
        const det = dataset.data[index];
        tp.push(det);
      }

      // const diamod = this.diamondShapeData[indexDiamond];
      const diamod = this.findDiamondPoint(x + this.x, y + this.y);
      if (diamod && this.isRealtime) {
        this.tooltip = {
          left:
            x < this.width * 0.2 ? x + this.width * 0.1 : x - this.width * 0.1,
          top: y + this.padding * 3,
          data: diamod,
          rt: true,
        };
        if(this.tooltip.top > this.height){
          this.tooltip.top =  y - this.padding * 3
        }
      } else {
        if (tp.length > 0) {
          this.tooltip = {
            left:
              x < this.width * 0.2
                ? x + this.width * 0.1
                : x - this.width * 0.1,
            top:
              y > this.height - this.height * 0.4
                ? y - this.height * 0.3
                : y < this.height * 0.4
                ? y + this.height * 0.2
                : y,
            data: tp,
            rt: false,
          };
        }
      }
    },
    crossHairMouseLeave() {
      this.contextBroomStick.clearRect(
        0,
        0,
        this.canvasBroomStick.width,
        this.canvasBroomStick.height
      );
      this.tooltip = null;
    },
  },
  async mounted() {
    const wellDet = this.$store.state.disp.displays[this.displayId];
    if (wellDet && wellDet.wellId) {
      console.log('well-graph::',wellDet);
      let mapping = await helperServices.getIndexDetails(wellDet.wellId);
      mapping = mapping.data.logs[wellDet.logType];
      const mnemonic = this.hklOrTor=='HOOKLOAD'?'hkl':'tor'
      const tags = mapping.tags;
      const mnemonicKey = tags.findIndex((t) => t == mnemonic);
      this.displayXUnit = mapping.units[mnemonicKey];
      const mnemonicKeyY = tags.findIndex((t) => t == 'dmea');
      this.displayYUnit = mapping.units[mnemonicKeyY];
console.log(this.displayYUnit,'-------------------',this.displayXUnit);
    }
    this.canvas = this.$refs[this.id];
    this.context = this.canvas.getContext("2d");
    this.canvasBroomStick = this.$refs["cursorCanvasBroomStick" + this.id];
    this.contextBroomStick = this.canvasBroomStick.getContext("2d");
    this.width = this.canvas.width - this.x - this.padding * 2;
    this.height =
      this.canvas.height - this.y - this.padding * 3 - this.fontHeight;
    this.inItChat();
  },
  watch: {
    data_set: {
      handler(newValue, oldValue) {
        newValue.forEach((d) => {
          if (d.data?.length > 0)
            this.drawLine(d.data, d.color, d.width, d.lineType);
        });
      },
      deep: true,
    },
    min_X(val) {
      this.minX = val;
      this.updateScale();
    },
    min_Y(val) {
      this.minY = val;
      this.updateScale();
    },
    max_X(val) {
      this.maxX = val;
      this.updateScale();
    },
    max_Y(val) {
      this.maxY = val;
      this.updateScale();
    },
    diamondShapeData(val) {
      if (val.length > 0 && this.isRealtime) {
        this.drawDiamond(val);
      }
    },
    wellFormationData(val) {
      if (val.length > 0) {
        this.drawHorizantalLine(val);
      }
    },
    geometryData(val) {
      if (val.length > 0) {
        this.drawGeoData(val);
      }
    },
  },
  expose: ["resetZoom"],
};
</script>
<style scoped>
.zoombtn {
  margin-top: 5px;
  font-size: 10px;
}
.tooltip {
  position: absolute;
  font-size: 10px;
  background: var(--sidebarListBgColor);
  width: auto;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
  pointer-events: none;
  white-space: pre;
  text-transform: uppercase;
  z-index: 999999;
  color: var(--textColor);
}
.cursor_can {
  position: absolute;
  z-index: 999999;
}
</style>
