import { ref, reactive } from "vue";
import apiService from "../api/services";
import store from '../store/index';

let completeData = ref(null);
const replayDetails = ref([]);
const tempDet = ref(null);
let timeInterval = ref(null);

export function useTndReplay() {

  async function setData(hklOrTor,dateTimeQuery,wellId,wellboreId,time_zone,dtls) {
    // timeInterval.value = null;
    await fetchDetailsFromDb(hklOrTor,dateTimeQuery,wellId,wellboreId,time_zone,dtls);
  }
  async function fetchDetailsFromDb(hklOrTor,dateTimeQuery,wellId,wellboreId,time_zone,dtls) {
    store.dispatch("data/startLoading");
      let result = await apiService.WellServices.getTorqueAndDragDataTime({
        graphFor: hklOrTor,
        ...dtls,
        ...dateTimeQuery,
        well_id: wellId,
        wellbore_name: wellboreId,
        time_zone: time_zone
      });
     completeData.value = result.data.rows;
    store.dispatch("data/stopLoading");
    if(timeInterval.value){
      timeInterval.value = clearInterval(timeInterval.value);
    }
        changeDetailByInterval(); 
  }
  function changeDetailByInterval() {
    // tempDet.value=[...completeData.value.rows];
    
    replayDetails.value = [];
    timeInterval.value = setInterval(() => {
      const tt = completeData.value.splice(0,10);
     replayDetails.value = [...replayDetails.value,...tt]
      if(completeData.value.length==0){
        timeInterval.value = clearInterval(timeInterval.value);
      }
    }, 3000);
  }
function clearRuningIntervals(){
  if(timeInterval.value){
    timeInterval.value = clearInterval(timeInterval.value);
  }
}
  return {
    setData,
    replayDetails,
    completeData,
    clearRuningIntervals
  };
}
